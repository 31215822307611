import { Box, Flex, Text, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { useSetState } from "@mantine/hooks";
import { Food, Forex, Logo, NewsFeedSlider, NewsTicker, Weather } from "../../components";
import { useTranslation } from "react-i18next";

export const View2 = () => {

    const theme = useMantineTheme();
    const colorScheme = useMantineColorScheme();
    const spacing = 20;

    const [cardStyle] = useSetState({
        backgroundColor: colorScheme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
        borderRadius: theme.radius.md,
        padding: theme.spacing.sm,
        overflow: 'hidden',
    });

    const { t } = useTranslation();

    return (
        <Flex
            style={{
                backgroundColor: colorScheme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0],
                width: '100%',
                height: '100%',
            }}
            p={spacing}
            gap={spacing}
            direction={'column'}
        >
            <Flex
                gap={spacing}
                style={{
                    height: `8%`,
                }}
            >
                <Flex
                    style={{
                        ...cardStyle,
                        width: '100%',
                        height: '100%',
                    }}
                    bg={'white'}
                    py={0}
                    px={0}
                    justify={'center'}
                    align={'center'}
                >
                    <NewsTicker />
                </Flex>
                <Logo
                    style={{
                        width: 150,
                    }}
                />
            </Flex>
            <Flex
                gap={spacing}
                style={{
                    height: `59.8%`,
                }}
            >
                <Box
                    style={{
                        ...cardStyle,
                        width: '60%',
                        height: '100%',
                    }}
                >

                </Box>
                <Box
                    style={{
                        ...cardStyle,
                        width: '40%',
                        height: '100%',
                    }}
                >
                    <NewsFeedSlider withHeader={false} />
                </Box>
            </Flex>
            <Flex
                gap={spacing}
                style={{
                    height: `32.2%`,
                }}
            >
                <Box
                    style={{
                        ...cardStyle,
                        width: '20%',
                        height: '100%',
                    }}
                >
                    <Flex justify={'center'} align={'center'} style={{ height: '100%' }} direction={'column'} gap={'lg'}>
                        <Box
                            style={{
                                backgroundColor: 'white',
                                padding: '10px',
                                borderRadius: '10px',
                            }}
                        >
                            <img width={200} src="https://api.qrserver.com/v1/create-qr-code/?size=350x350&data=https://964media.com/app/download" alt="QR Code" />
                        </Box>
                        <Text size={'2rem'} fw={'bold'}>
                            {t('حمل التطبيق الآن')}
                        </Text>
                    </Flex>
                </Box>
                <Weather
                    style={{
                        ...cardStyle,
                        width: '20%',
                        height: '100%',
                    }}
                />
                <Forex
                    style={{
                        ...cardStyle,
                        width: '20%',
                        height: '100%',
                    }}
                />
                <Food
                    style={{
                        ...cardStyle,
                        width: '20%',
                        height: '100%',
                    }}
                />
                <Box
                    style={{
                        ...cardStyle,
                        width: '20%',
                        height: '100%',
                        backgroundImage: 'url(https://964tube.com/wp-content/uploads/2024/08/B5NAckADlFjgrtKKoeQGcYqs07KhbujSQ5ragjQrVb5BAHu1HRunrEb17tSn.jpg)',
                        backgroundSize: 'cover',
                    }}
                >
                </Box>
            </Flex>
        </Flex>
    );
}